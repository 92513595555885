import { IGalleryImages, IProjectDetails } from '@/interfaces/IHomeContent';
import { createContext, useContext } from 'react';
export type ICanvasContent = {
    currentTool: string,
    setCurrentTool: Function,
    colors: { inner: string, outer: string },
    setColors: Function,
    canvasImages: string[] | null
    setCanvasImages: Function
    selectedShapeId: number | null
    setSelectedShapeId: Function
    imageUploadRef: any
    tableImageUploadRef: any
    setUploadedImage: Function
    uploadedImage: any
    setSlideContent: Function
    galleryImages: IGalleryImages[]
    setComment: Function
    comment: Boolean
    isOnline: Boolean
    projectDetails: IProjectDetails
    comments: any
    setComments: Function
    customSlides: any[]
    zoomState: any
    setZoomState: Function
    editedJson:any
    setEditedJson:Function
    selectedShapes:any[]
    setSelectedShapes:Function
    clipboard: any[]
    setClipboard:Function
    isMagnifying: Boolean,
    setIsMagnifying: Function
    strokeWidth:number, 
    setStrokeWidth:Function,
    deleteTools:Function,
    updateCommentsJson:Function,
    commentsIds:any[],
    data:any,
    BoqVerisonIsLatest:Function
    copiedTable:any,
    setCopiedTable:Function,
    selectedCells:any, setSelectedCells:Function

}

export const CanvasContext = createContext<Partial<ICanvasContent>>({});
export const useCanvasContext = () => useContext(CanvasContext)